import axios from 'axios';
import { getItem } from './localStorage'

const API_ENDPOINT = "https://nomadic.altamimi.dev/";



const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
});

class MultiPartDataService {

  static post(path = '', form = null, progress = null) {


    return client({
      method: 'POST',
      url: path,
      data: form,
      onUploadProgress: (progressEvent) => {
        progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    });
  }

  static patch(path = '', form = null) {
    return client({
      method: 'PATCH',
      url: path,
      data: form,
    });
  }

  static put(path = '', form = null) {
    return client({
      method: 'PUT',
      url: path,
      data: form,
    });
  }
}


/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers,  Authorization: getItem('token') };


  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);





export { MultiPartDataService };
