import { DataService } from "@/utils/dataService";

import { MultiPartDataService } from "@/utils/multiPartService"

async function getAllTribes(params) {
    return await DataService.get('admin/tribe/getAllTribes', params)
}

async function getAllPrograms(params){
    return await DataService.get('/admin/program/getAll',params)
}

async function postPrograms(params){
    return await DataService.post('/admin/program/create',params)
}
async function editProgram(params){
    return await DataService.post('/admin/program/update',params)
}

async function uplodDetailsImage(params){
    return await DataService.post('/admin/program/uploadImage',params)
}
async function getTribes(params){
    return await DataService.get('/admin/tribe/getAll',params)
}
async function uploadCoverImageService(form, progress) {
    return await MultiPartDataService.post('admin/tribe/fileUpload', form, progress)
}

async function uploadCoverVideoService(form, progress) {
    return await MultiPartDataService.post('admin/tribe/fileUpload', form, progress)
}
async function uploadImageService(form, progress) {
    return await MultiPartDataService.post('admin/tribe/fileUpload', form, progress)
}
async function postTribeService(data){
    return await DataService.post('admin/tribe/create', data)
}
async function deleteTribes(params){
    return await DataService.post('/admin/tribe/deleteTribe?tribeId='+params.tribeId,params)
}
async function updateTribe(params){
    return await DataService.post('/admin/tribe/update',params)
}
export{ 
    getAllTribes,
    getAllPrograms,
    postPrograms,
    uplodDetailsImage,
    getTribes,
    editProgram,
    uploadCoverImageService,
    uploadCoverVideoService,
    uploadImageService,
     postTribeService,
     deleteTribes,
     updateTribe
}