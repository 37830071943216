
<script>
import Vue from "vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { DataService } from "../../../utils/dataService";
import { getAllPrograms } from "@/services/api/tribe";
import { getUserType } from "@/services/setUserType";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      showEdit: true,
      type: "",
      title: "All Programms",
      isLoading: false,
      listProgram: [],
      submitted: false,
      rows: 0,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      currentPage: 1,
    };
  },

  mounted() {
    console.log(this.$type);
  },
  created() {
    this.proList();
    this.checkUserType();
  },

  methods: {
    goToAddProgramms() {
      this.$router.push("addProgramms");
    },
    checkUserType() {
      console.log(getUserType());
      this.type = getUserType();
      console.log(this.type);
      if (this.type == "admin") {
        this.showEdit = false;
      } else {
        this.showEdit == true;
      }
      console.log(this.showEdit);
    },
    proList() {
      console.log(getUserType());
      this.isLoading = true;
      this.listProgram = [];
      getAllPrograms({
        type: getUserType(),
        page: this.currentPage,
        perPage: this.perPage,
        role: "Normal",
      })
        .then((res) => {
          console.log(res.data);
          this.listProgram = res.data.programs.docs;
          this.rows = res.data.programs.totalDocs;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteProgram(id) {
      if (
        this.$bvModal
          .msgBoxConfirm("Do you really want to delete?")
          .then((e) => {
            if (e == true) {
              DataService.post("/admin/program/delete", { programId: id });
              this.proList();
            }
          })
      );
    },

    pageChange(val) {
      this.currentPage = val;
      this.proList();
    },

    perPageChange() {
      this.proList();
    },

    created() {
      this.proList();
    },

    goToEditProgramms(program) {
      this.$router.push({
        name: "editProgramms",
        params: { program: program },
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; justify-content: space-between">
              <a
                v-if="showEdit == true"
                href="javascript:void(0);"
                style="padding: 5px 17px"
                class="btn btn-success mb-2"
                @click="goToAddProgramms()"
              >
                <i class="mdi mdi-plus mr-2"></i>Add Program
              </a>
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>

            <div class="no-data" v-if="!dataLoading && !rows">No Programs</div>

            <!-- {{ this.showEdit }}  -->
            <div v-if="isLoading == true">
              <img
                style="display: block; margin-left: auto; margin-right: auto"
                class="center"
                src="@/assets/images/loading2.gif"
                alt="Header Avatar"
              />
            </div>

            <div v-else>
              <div class="table-responsive mt-3">
                <table
                  :items="listProgram"
                  responsive="sm"
                  :fields="fields"
                  :busy="isLoading"
                  :per-page="perPage"
                  :hover="true"
                  thead-class="d-none"
                  class="table table-centered datatable dt-responsive nowrap"
                  style="
                    border-collapse: collapse;
                    border-spacing: 0;
                    width: 100%;
                  "
                >
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Price</th>
                      <th scope="col">Minimum People</th>
                      <th scope="col">Maximum People</th>
                      <th scope="col">Included</th>
                      <th scope="col">Excluded</th>
                      <th scope="col">Cancellation Policy</th>
                      <th scope="col">Banner</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      tr
                      v-for="listProgram in listProgram"
                      v-bind:key="listProgram._id"
                    >
                      <td>{{ listProgram.name }}</td>
                      <td>{{ listProgram.description }}</td>
                      <td>{{ listProgram.price.adults }}</td>
                      <td>{{ listProgram.more_details.min_people }}</td>
                      <td>{{ listProgram.more_details.max_people }}</td>
                      <td>{{ listProgram.more_details.included }}</td>
                      <td>{{ listProgram.more_details.excluded }}</td>
                      <td>{{ listProgram.cancellation_policy }}</td>
                      <td><img :src="listProgram.banner" height="50" /></td>
                      <td>
                        <a
                          v-if="showEdit == true"
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Edit"
                          @click="goToEditProgramms(listProgram)"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="deleteProgram(listProgram._id)"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--modelStart from here-->
    <div class="row" v-if="rows">
      <div class="col-sm-5">
        <div class="float-left">
          <label
            class="d-inline-flex align-items-center"
            style="min-width: 45px"
          >
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.center img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
table {
  width: 1300px;

  border-color: black;
}
table th {
  text-transform: uppercase;
  text-align: left;
  color: #fff;
}

table td {
  text-align: left;
  padding-top: 20px;
}
.row:hover {
  cursor: pointer;
}
.no-data {
  text-align: center;
  padding-top: 14px;
  font-size: 16px;
  font-weight: 600;
}
</style>